import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';

import { SwiperModule } from 'swiper/angular';

import { CardComponent } from './components/card/card.component';
import { CarouselHeroComponent } from './components/carousel-hero/carousel-hero.component';
import { CarouselThumbComponent } from './components/carousel-thumb/carousel-thumb.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { AccordionDirective } from './directives/accordion.directive';
import { ClickOutsideDirective } from './directives/clickoutside.directive';
import { LayoutPagesComponent } from './layouts/layout-pages/layout-pages.component';
import { KebabCasePipe } from './pipes/kebab-case.pipe';

@NgModule({
    declarations: [
        //,
        LayoutPagesComponent,
        CarouselComponent,
        CarouselHeroComponent,
        CarouselThumbComponent,
        HeaderComponent,
        FooterComponent,
        AccordionDirective,
        ClickOutsideDirective,
        CardComponent,
        KebabCasePipe,
    ],
    imports: [CommonModule, RouterModule, FormsModule, NgOptimizedImage, SwiperModule, TranslocoModule],
    providers: [],
    exports: [
        //,
        LayoutPagesComponent,
        CarouselComponent,
        CarouselHeroComponent,
        CarouselThumbComponent,
        AccordionDirective,
        FooterComponent,
        HeaderComponent,
        CardComponent,
        ClickOutsideDirective,
        KebabCasePipe,
    ],
})
export class SharedModule {}
