import { TranslocoService } from '@ngneat/transloco';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Constants } from '@app/core/constants/constants';

import { LocalStorageManagementService } from './local-storage-management.service';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    constructor(
        //
        private localStorageManagementService: LocalStorageManagementService,
        private translocoService: TranslocoService,
        private router: Router
    ) {}

    setupAppLanguage(): void {
        let language = this.localStorageManagementService.getItem(Constants.LANGUAGE);

        if (!language) {
            language = navigator.language || Constants.LANGUAGE_ITALIAN;
            language = language.split('-')[0];
            this.localStorageManagementService.setItem(Constants.LANGUAGE, Constants.LANGUAGE_ITALIAN);
            this.localStorageManagementService.setItem(Constants.LANGUAGE_INDEX, Constants.LANGUAGE_ITALIAN_INDEX);
        }

        this.translocoService.setActiveLang(language);
    }

    switchLanguage(lang: string, index: string, redirectPage: string | null = null): void {
        this.translocoService.setActiveLang(lang);
        this.localStorageManagementService.setItem(Constants.LANGUAGE, lang);
        this.localStorageManagementService.setItem(Constants.LANGUAGE_INDEX, index);
        // Reload current page
        if (redirectPage) {
            this.router.navigate([redirectPage]);
        } else {
            window.location.reload();
        }
    }
}
