import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs';

import { AppSetting } from '@app/core/models/base/app-setting.model';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class AppSettingService {
    public appSetting!: AppSetting;

    constructor(private http: HttpClient) {}

    loadAppSetting(): any {
        return this.retrieveSettings()
            .then((config: AppSetting | void) => {
                if (config) {
                    this.appSetting = config;
                    this.overrideStaticConfiguration(config);
                }
            })
            .catch(() => {
                this.appSetting = environment;
            });
    }

    async retrieveSettings(): Promise<AppSetting> {
        const config = this.http.get<AppSetting>('assets/settings/settings.json');
        return await lastValueFrom(config);
    }

    private overrideStaticConfiguration(config: AppSetting): void {
        environment.production = config.production;
        environment.feUrl = config.feUrl;
        environment.apiUrl = config.apiUrl;
        environment.algoliaApplicationId = config.algoliaApplicationId;
        environment.algoliaApiKey = config.algoliaApiKey;
    }
}
