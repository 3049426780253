import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Constants } from '../constants/constants';
import { Territory, TerritoryDetail } from '../models/territory.model';
import { LocalStorageManagementService } from './local-storage-management.service';

@Injectable({
    providedIn: 'root',
})
export class TerritoryService {
    constructor(
        //
        private http: HttpClient,
        private localStorageManagementService: LocalStorageManagementService
    ) {}

    // ─── HTTP REQUESTS - GET ─────────────────────────────────────────────────────

    getTerritories(): Observable<Territory> {
        const languageID = this.localStorageManagementService.getItem(Constants.LANGUAGE_INDEX);
        return this.http.get<Territory>(`items/territories?fields=*,language.*&deep[language][_filter][languages_code][_eq]=${languageID}`);
    }

    getTerritory(idTerritory: number): Observable<TerritoryDetail> {
        const languageID = this.localStorageManagementService.getItem(Constants.LANGUAGE_INDEX);
        return this.http.get<TerritoryDetail>(
            `items/territories/${idTerritory}?fields=*.*,map_id.*,map_id.language.*,language.*&deep[map_id][language][_filter][languages_code][_eq]=${languageID}&deep[language][_filter][languages_code][_eq]=${languageID}`
        );
    }
}
