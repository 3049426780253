import { Component, OnInit } from '@angular/core';

import { MiscService } from '@app/core/services/misc.service';
import { ProductService } from '@app/core/services/products.service';
import { TerritoryService } from '@app/core/services/territories.service';

import { Constants } from '@app/core/constants/constants';
import { Misc } from '@app/core/models/misc.model';
import { MasterProduct } from '@app/core/models/product.model';
import { Territory } from '@app/core/models/territory.model';

@Component({
    selector: 'rav-layout-pages',
    templateUrl: './layout-pages.component.html',
    styleUrls: ['./layout-pages.component.scss'],
})
export class LayoutPagesComponent implements OnInit {
    public masterProducts!: MasterProduct;
    public territories!: Territory;
    public misc!: Misc;
    public constants = Constants;

    constructor(
        //,
        private productService: ProductService,
        private territoryService: TerritoryService,
        private miscService: MiscService
    ) {}

    ngOnInit(): void {
        this.getMasterProduct();
        this.getTerritories();
        this.getMisc();
    }

    // ─── HTTP REQUEST ────────────────────────────────────────────────────────────

    getMasterProduct(): void {
        this.productService.getMasterTopProducts().subscribe((master_product: MasterProduct) => {
            this.masterProducts = master_product;
        });
    }

    getTerritories(): void {
        this.territoryService.getTerritories().subscribe((territories: Territory) => {
            this.territories = territories;
        });
    }

    getMisc(): void {
        this.miscService.getMisc().subscribe((misc: Misc) => {
            this.misc = misc;
        });
    }
}
