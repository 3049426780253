<div
    class="rav-header"
    [ngStyle]="{ backgroundColor: isColored }"
    [ngClass]="{
        '-openedMenu': isMenuOpen,
        '-openedSearch': isSearchOpen,
        '-openedSearchResults': isSearchOpen && isSearchResultOpen,
        '-brand-01-600': isColored
    }"
>
    <div class="rav-layout">
        <div class="rav-section -row -wide -top">
            <img
                [routerLink]="['/']"
                class="rav-image -logo"
                [src]="
                    isColored
                        ? '/assets/images-opt/logos/logo-radici-virtuose-light-horizontal.svg'
                        : '/assets/images-opt/logos/logo-radici-virtuose-dark-horizontal.svg'
                "
                width="151"
                height="32"
                alt="Logo Radici virtuose"
            />
            <ul class="rav-header__lang">
                <li class="rav-header__langItem">
                    <button
                        class="rav-button -current"
                        [ngClass]="{ '-current': currentLanguage === constants.LANGUAGE_ITALIAN }"
                        type="button"
                        aria-label="Italian language"
                        (click)="switchLanguage(constants.LANGUAGE_ITALIAN, constants.LANGUAGE_ITALIAN_INDEX)"
                    >
                        <h5 class="rav-copy -titleXS" [ngClass]="isColored ? '-brand-03' : '-brand-02'">It</h5>
                    </button>
                </li>
                <li class="rav-header__langItem">
                    <button
                        [ngClass]="{ '-current': currentLanguage === constants.LANGUAGE_ENGLISH }"
                        class="rav-button"
                        type="button"
                        aria-label="English language"
                        (click)="switchLanguage(constants.LANGUAGE_ENGLISH, constants.LANGUAGE_ENGLISH_INDEX)"
                    >
                        <h5 class="rav-copy -titleXS" [ngClass]="isColored ? '-brand-03' : '-brand-02'">En</h5>
                    </button>
                </li>
                <!-- <li class="rav-header__langItem">
                    <button
                        [ngClass]="{ '-current': currentLanguage === constants.LANGUAGE_GERMAN }"
                        class="rav-button"
                        type="button"
                        aria-label="German language"
                        (click)="switchLanguage(constants.LANGUAGE_GERMAN, constants.LANGUAGE_GERMAN_INDEX)"
                    >
                        <h5 class="rav-copy -titleXS" [ngClass]="isColored ? '-brand-03' : '-brand-02'">De</h5>
                    </button>
                </li> -->
                <!-- <li class="rav-header__langItem">
                    <button
                        [ngClass]="{ '-current': currentLanguage === constants.LANGUAGE_FRENCH }"
                        class="rav-button"
                        type="button"
                        aria-label="French language"
                        (click)="switchLanguage(constants.LANGUAGE_FRENCH, constants.LANGUAGE_FRENCH_INDEX)"
                    >
                        <h5 class="rav-copy -titleXS" [ngClass]="isColored ? '-brand-03' : '-brand-02'">Fr</h5>
                    </button>
                </li> -->
            </ul>
            <div class="rav-search">
                <div class="rav-textField">
                    <input #searchBar id="search" class="rav-textField__input" [value]="search" type="search" placeholder="Search..." />
                    <button
                        class="rav-button -expandSearch"
                        type="button"
                        aria-label="expand search"
                        (click)="isSearchOpen = !isSearchOpen"
                    >
                        <i class="rav-icon rav-icon-search" [ngClass]="isColored ? '-brand-03' : '-brand-02'"></i>
                    </button>
                </div>

                <button
                    class="rav-button -big -brand-04 -collapseSearch"
                    (click)="closeMenuAndSearch()"
                    type="button"
                    aria-label="collapse search"
                >
                    <i class="rav-icon rav-icon-close -brand-03"></i>
                </button>
            </div>

            <button class="rav-button -big -brand-04 -menu" (click)="toggleMenu()" type="button" aria-label="menu">
                <i class="rav-icon rav-icon-menu -brand-03"></i>
            </button>
        </div>
        <div class="rav-section -row -wide -menu">
            <div class="rav-block -col -imageSlider">
                <swiper #swiperHeader [config]="swiperHeaderConfig" class="-imageSlider swiper">
                    <ng-template swiperSlide>
                        <picture class="rav-image">
                            <source
                                class="rav-image__source"
                                ngSrcset="/assets/images-opt/graphics/graphic-header-menu-image-placeholder-one.png"
                                type="image/png"
                            />
                            <source
                                class="rav-image__source"
                                ngSrcset="/assets/images-opt/graphics/graphic-header-menu-image-placeholder-one.webp"
                                type="image/webp"
                            />
                            <img
                                class="rav-image__img"
                                ngSrc="/assets/images-opt/graphics/graphic-header-menu-image-placeholder-one.png"
                                width="720"
                                height="710"
                                alt="image header slider"
                            />
                        </picture>
                    </ng-template>
                    <ng-template swiperSlide>
                        <picture class="rav-image">
                            <source
                                class="rav-image__source"
                                ngSrcset="/assets/images-opt/graphics/graphic-header-menu-image-placeholder-two.png"
                                type="image/png"
                            />
                            <source
                                class="rav-image__source"
                                ngSrcset="/assets/images-opt/graphics/graphic-header-menu-image-placeholder-two.webp"
                                type="image/webp"
                            />
                            <img
                                class="rav-image__img"
                                ngSrc="/assets/images-opt/graphics/graphic-header-menu-image-placeholder-two.png"
                                width="720"
                                height="710"
                                alt="image header slider"
                            />
                        </picture>
                    </ng-template>
                </swiper>
            </div>
            <div class="rav-block -col -nav" *transloco="let t">
                <div
                    class="rav-accordion -reverse -borderless"
                    ravAccordion
                    [accordionName]="'territories'"
                    (accordionOpened)="closeOthersAccordions($event)"
                    data-group="nav"
                >
                    <div class="rav-accordion__header" role="button">
                        <h5 class="rav-copy -titleL">{{ t('header.discover_territory') }}</h5>

                        <div class="rav-icon rav-icon-plus -brand-01-500"></div>
                    </div>
                    <div class="rav-accordion__container">
                        <div class="rav-accordion__content">
                            <ul class="rav-accordion__list">
                                <li class="rav-list__linksItem" *ngFor="let item of territory?.data">
                                    <p class="rav-copy -titleXS" *ngIf="item.language[0]">
                                        <a (click)="toggleMenu()" [routerLink]="[constants.ROUTE_TERRITORY, item.id]">
                                            {{ item.language[0].title }}
                                        </a>
                                    </p>
                                </li>
                                <li class="rav-list__linksItem">
                                    <p class="rav-copy -titleXS">
                                        <a
                                            (click)="toggleMenu()"
                                            [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_INTEREST_PLACE]"
                                            >{{ t('header.interest_place') }}</a
                                        >
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div
                    class="rav-accordion -reverse -borderless"
                    ravAccordion
                    [accordionName]="'topProducts'"
                    (accordionOpened)="closeOthersAccordions($event)"
                    data-group="nav"
                >
                    <div class="rav-accordion__header" role="button">
                        <h5 class="rav-copy -titleL">{{ t('header.top_product') }}</h5>

                        <div class="rav-icon rav-icon-plus -brand-01-500"></div>
                    </div>
                    <div class="rav-accordion__container">
                        <div class="rav-accordion__content">
                            <ul class="rav-accordion__list">
                                <li class="rav-list__linksItem" *ngFor="let item of master?.data">
                                    <p class="rav-copy -titleXS" *ngIf="item.language[0]">
                                        <a
                                            (click)="toggleMenu()"
                                            [routerLink]="[constants.ROUTE_MASTER_PRODUCT, item.language[0].master_top_products_id]"
                                            >{{ item.language[0].title }}</a
                                        >
                                    </p>
                                </li>
                                <li class="rav-list__linksItem">
                                    <p class="rav-copy -titleXS">
                                        <a
                                            (click)="toggleMenu()"
                                            [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_RECIPES_DETAIL]"
                                            >{{ t('header.regional_recipes') }}</a
                                        >
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div
                    class="rav-accordion -reverse -borderless"
                    ravAccordion
                    [accordionName]="'experiences'"
                    (accordionOpened)="closeOthersAccordions($event)"
                    data-group="nav"
                >
                    <div class="rav-accordion__header" role="button">
                        <h5 class="rav-copy -titleL">{{ t('header.experiences') }}</h5>

                        <div class="rav-icon rav-icon-plus -brand-01-500"></div>
                    </div>
                    <div class="rav-accordion__container">
                        <div class="rav-accordion__content">
                            <ul class="rav-accordion__list">
                                <li class="rav-list__linksItem">
                                    <p class="rav-copy -titleXS">
                                        <a (click)="toggleMenu()" [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_TOURS]">{{
                                            t('header.tours')
                                        }}</a>
                                    </p>
                                </li>
                                <li class="rav-list__linksItem">
                                    <p class="rav-copy -titleXS">
                                        <a
                                            (click)="toggleMenu()"
                                            [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_INTEREST_PLACE]"
                                        >
                                            {{ t('header.interest_place') }}</a
                                        >
                                    </p>
                                </li>
                                <!-- <li class="rav-list__linksItem">
                                    <p class="rav-copy -titleXS">
                                        <a (click)="toggleMenu()" [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_RECIPES]">{{
                                            t('header.tasting')
                                        }}</a>
                                    </p>
                                </li> -->
                                <li class="rav-list__linksItem">
                                    <p class="rav-copy -titleXS">
                                        <a (click)="toggleMenu()" [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_EVENTS]">{{
                                            t('header.events')
                                        }}</a>
                                    </p>
                                </li>
                                <!-- <li class="rav-list__linksItem">
                                    <p class="rav-copy -titleXS">
                                        <a (click)="toggleMenu()" [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_MEDIA]">{{
                                            t('header.media')
                                        }}</a>
                                    </p>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div
                    class="rav-accordion -reverse -borderless"
                    ravAccordion
                    [accordionName]="'projects'"
                    (accordionOpened)="closeOthersAccordions($event)"
                    data-group="nav"
                >
                    <div class="rav-accordion__header" role="button">
                        <h5 class="rav-copy -titleL">{{ t('header.name_project') }}</h5>

                        <div class="rav-icon rav-icon-plus -brand-01-500"></div>
                    </div>
                    <div class="rav-accordion__container">
                        <div class="rav-accordion__content">
                            <ul class="rav-accordion__list">
                                <li class="rav-list__linksItem" *ngFor="let item of miscs?.data">
                                    <p class="rav-copy -titleXS" *ngIf="item.language[0]">
                                        <a
                                            [routerLink]="[constants.ROUTE_GENERIC_ARTICLE, constants.ARTICLE_MISC, item.id]"
                                            (click)="toggleMenu()"
                                            >{{ item.language[0].title }}</a
                                        >
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="rav-socials">
                    <p class="rav-copy -textM -uppercase -bold">
                        <a
                            href="mailto: info@radicivirtuose.it
                        amministrazione@radicivirtuose.it
                        comunicazione@radicivirtuose.it"
                            ><i>{{ t('header.write_to_us') }}</i>
                        </a>
                    </p>

                    <ul class="rav-socials__list">
                        <li class="rav-socials__listItem">
                            <a class="rav-button" href="https://www.facebook.com/radicivirtuose" target="_blank" aria-label="facebook link">
                                <i class="rav-icon rav-icon-facebook -brand-02"></i>
                            </a>
                        </li>
                        <li class="rav-socials__listItem">
                            <a
                                class="rav-button"
                                href="https://www.youtube.com/channel/UCROPzD7hx8cquxeZu3QYTlg"
                                target="_blank"
                                aria-label="youtube link"
                            >
                                <i class="rav-icon rav-icon-youtube -brand-02"></i>
                            </a>
                        </li>
                        <li class="rav-socials__listItem">
                            <a
                                class="rav-button"
                                href="https://www.instagram.com/radicivirtuose"
                                target="_blank"
                                aria-label="instagram link"
                            >
                                <i class="rav-icon rav-icon-instagram -brand-02"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="rav-subMenu" *transloco="let t">
                <swiper #swiperSubMenu [config]="swiperSubMenuConfig" class="swiper -subMenu">
                    <ng-template swiperSlide>
                        <ul class="rav-subMenu__navList">
                            <li class="rav-subMenu__navListItem">
                                <button class="rav-button" type="button" (click)="goToSubMenu(1)" aria-label="search territory">
                                    <h5 class="rav-copy -titleXL">{{ t('header.discover_territory') }}</h5>
                                </button>
                            </li>
                            <li class="rav-subMenu__navListItem">
                                <button class="rav-button" type="button" (click)="goToSubMenu(2)" aria-label="top product">
                                    <h5 class="rav-copy -titleXL">{{ t('header.top_product') }}</h5>
                                </button>
                            </li>
                            <li class="rav-subMenu__navListItem">
                                <button class="rav-button" type="button" (click)="goToSubMenu(3)" aria-label="experiences">
                                    <h5 class="rav-copy -titleXL">{{ t('header.experiences') }}</h5>
                                </button>
                            </li>
                            <li class="rav-subMenu__navListItem">
                                <button class="rav-button" type="button" (click)="goToSubMenu(4)" aria-label="radici virtuose">
                                    <h5 class="rav-copy -titleXL">{{ t('header.name_project') }}</h5>
                                </button>
                            </li>
                        </ul>
                        <div class="rav-socials">
                            <p class="rav-copy -textM -uppercase -bold">
                                <a
                                    href="mailto: info@radicivirtuose.it
                                amministrazione@radicivirtuose.it
                                comunicazione@radicivirtuose.it"
                                    ><i> {{ t('header.write_to_us') }}</i></a
                                >
                            </p>

                            <ul class="rav-socials__list">
                                <li class="rav-socials__listItem">
                                    <a
                                        class="rav-button"
                                        href="https://www.facebook.com/radicivirtuose"
                                        target="_blank"
                                        aria-label="facebook link"
                                    >
                                        <i class="rav-icon rav-icon-facebook -brand-02"></i>
                                    </a>
                                </li>
                                <li class="rav-socials__listItem">
                                    <a
                                        class="rav-button"
                                        href="https://www.youtube.com/channel/UCROPzD7hx8cquxeZu3QYTlg"
                                        target="_blank"
                                        aria-label="youtube link"
                                    >
                                        <i class="rav-icon rav-icon-youtube -brand-02"></i>
                                    </a>
                                </li>
                                <li class="rav-socials__listItem">
                                    <a
                                        class="rav-button"
                                        href="https://www.instagram.com/radicivirtuose"
                                        target="_blank"
                                        aria-label="instagram link"
                                    >
                                        <i class="rav-icon rav-icon-instagram -brand-02"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <button class="rav-button -back" type="button" (click)="goToSubMenu(0)" aria-label="territory discover">
                            <h5 class="rav-copy -textM -bold">{{ t('header.discover_territory') }}</h5>

                            <i class="rav-icon rav-icon-arrow-left -brand-01-500"></i>
                        </button>

                        <ul class="rav-subMenu__subNavList">
                            <li class="rav-subMenu__subNavListItem" *ngFor="let item of territory?.data">
                                <a
                                    *ngIf="item.language[0]"
                                    class="rav-button"
                                    (click)="toggleMenu()"
                                    [routerLink]="[constants.ROUTE_TERRITORY, item.id]"
                                >
                                    <h5 class="rav-copy -titleM">{{ item.language[0].title }}</h5>
                                </a>
                            </li>
                            <li class="rav-subMenu__subNavListItem">
                                <a (click)="toggleMenu()" [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_INTEREST_PLACE]">
                                    <h5 class="rav-copy -titleM">{{ t('header.interest_place') }}</h5>
                                </a>
                            </li>
                        </ul>
                    </ng-template>
                    <ng-template swiperSlide>
                        <button class="rav-button -back" type="button" (click)="goToSubMenu(0)" aria-label="top product">
                            <h5 class="rav-copy -textM -bold">{{ t('header.top_product') }}</h5>

                            <i class="rav-icon rav-icon-arrow-left -brand-01-500"></i>
                        </button>

                        <ul class="rav-subMenu__subNavList">
                            <li class="rav-list__linksItem" *ngFor="let item of master?.data">
                                <p class="rav-subMenu__subNavListItem" *ngIf="item.language[0]">
                                    <a class="rav-button" (click)="toggleMenu()" [routerLink]="[constants.ROUTE_MASTER_PRODUCT, item.id]">
                                        <h5 class="rav-copy -titleM">{{ item.language[0].title }}</h5>
                                    </a>
                                </p>
                            </li>
                            <li class="rav-subMenu__subNavListItem">
                                <p class="rav-subMenu__subNavListItem">
                                    <a
                                        class="rav-button"
                                        (click)="toggleMenu()"
                                        [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_RECIPES_DETAIL]"
                                    >
                                        <h5 class="rav-copy -titleM">{{ t('header.regional_recipes') }}</h5>
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </ng-template>
                    <ng-template swiperSlide>
                        <button class="rav-button -back" type="button" (click)="goToSubMenu(0)" aria-label="experiences">
                            <h5 class="rav-copy -textM -bold">{{ t('header.experiences') }}</h5>

                            <i class="rav-icon rav-icon-arrow-left -brand-01-500"></i>
                        </button>

                        <ul class="rav-subMenu__subNavList">
                            <li class="rav-subMenu__subNavListItem">
                                <p class="rav-subMenu__subNavListItem">
                                    <a
                                        class="rav-button"
                                        (click)="toggleMenu()"
                                        [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_TOURS]"
                                        ><h5 class="rav-copy -titleM">{{ t('header.tours') }}</h5>
                                    </a>
                                </p>
                            </li>
                            <li class="rav-subMenu__subNavListItem">
                                <p class="rav-subMenu__subNavListItem">
                                    <a
                                        class="rav-button"
                                        (click)="toggleMenu()"
                                        [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_INTEREST_PLACE]"
                                    >
                                        <h5 class="rav-copy -titleM">{{ t('header.interest_place') }}</h5>
                                    </a>
                                </p>
                            </li>
                            <!-- <li class="rav-subMenu__subNavListItem">
                                <p class="rav-subMenu__subNavListItem">
                                    <a
                                        class="rav-button"
                                        (click)="toggleMenu()"
                                        [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_RECIPES]" // LINK SBAGLIATO
                                    >

                                        <h5 class="rav-copy -titleM">{{ t('header.tasting') }}</h5></a
                                    >
                                </p>
                            </li> -->
                            <li class="rav-subMenu__subNavListItem">
                                <p class="rav-subMenu__subNavListItem">
                                    <a
                                        class="rav-button"
                                        (click)="toggleMenu()"
                                        [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_EVENTS]"
                                    >
                                        <h5 class="rav-copy -titleM">{{ t('header.events') }}</h5>
                                    </a>
                                </p>
                            </li>
                            <!-- <li class="rav-subMenu__subNavListItem">
                                <p class="rav-subMenu__subNavListItem">
                                    <a
                                        class="rav-button"
                                        (click)="toggleMenu()"
                                        [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_MEDIA]"
                                    >
                                        <h5 class="rav-copy -titleM">{{ t('header.media') }}</h5>
                                    </a>
                                </p>
                            </li> -->
                        </ul>
                    </ng-template>
                    <ng-template swiperSlide>
                        <button class="rav-button -back" type="button" (click)="goToSubMenu(0)" aria-label="radici virtuose">
                            <h5 class="rav-copy -textM -bold">{{ t('header.name_project') }}</h5>

                            <i class="rav-icon rav-icon-arrow-left -brand-01-500"></i>
                        </button>

                        <ul class="rav-subMenu__subNavList">
                            <li class="rav-subMenu__subNavListItem" *ngFor="let item of miscs?.data">
                                <a
                                    *ngIf="item.language[0]"
                                    class="rav-button"
                                    [routerLink]="[constants.ROUTE_GENERIC_ARTICLE, constants.ARTICLE_MISC, item.id]"
                                    (click)="toggleMenu()"
                                    ><h5 class="rav-copy -titleM">{{ item.language[0].title }}</h5>
                                </a>
                            </li>
                        </ul>
                    </ng-template>
                </swiper>
            </div>
        </div>
    </div>
    <div class="rav-layout -searchResults" *transloco="let t">
        <div class="rav-section -row" *ngIf="!showNoResultText">
            <a
                class="rav-columnedCard -squared"
                *ngFor="let result of filteredResponse"
                [routerLink]="calculatePathByCategory(result._collection, result.objectID, result.media_type)"
                (click)="closeMenuAndSearch()"
            >
                <picture class="rav-image">
                    <img [ngSrc]="result.title_image" class="rav-image__img" width="1020" height="550" alt="" />
                </picture>

                <div class="rav-columnedCard__info">
                    <h4 class="rav-copy -titleS">{{ result.title }}</h4>

                    <div class="rav-columnedCard__tags">
                        <p class="rav-copy -textS -ui-01 -bold -uppercase -tags" *ngIf="result?.tags">
                            <i *ngFor="let tag of result?.tags"> #{{ tag }} </i>
                        </p>
                    </div>
                </div>
            </a>
        </div>
        <div class="rav-chapter__wrap" *ngIf="showNoResultText">
            <h2 class="rav-copy -titleM">{{ t('header.no_result_text') }}</h2>
        </div>
    </div>
</div>
