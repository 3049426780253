<div class="rav-footer" *transloco="let t">
    <div class="rav-layout">
        <div class="rav-section -col -medium -logos">
            <div class="rav-block -row -logo">
                <img
                    class="rav-image"
                    ngSrc="/assets/images-opt/logos/logo-radici-virtuose-dark-vertical.svg"
                    width="80"
                    height="180"
                    alt="Logo Radici Virtuose"
                />
            </div>
            <div class="rav-block -row -partners">
                <div class="rav-partners">
                    <picture class="rav-image">
                        <source class="rav-image__source" ngSrcset="/assets/images-opt/logos/logo-mipaaf.png" type="image/png" />
                        <source class="rav-image__source" ngSrcset="/assets/images-opt/logos/logo-mipaaf.webp" type="image/webp" />
                        <img class="rav-image__img" ngSrc="/assets/images-opt/logos/logo-mipaaf.png" width="70" height="35" alt="" />
                    </picture>
                    <picture class="rav-image">
                        <source
                            class="rav-image__source"
                            ngSrcset="/assets/images-opt/logos/logo-olio-di-puglia-igp.png"
                            type="image/png"
                        />
                        <source
                            class="rav-image__source"
                            ngSrcset="/assets/images-opt/logos/logo-olio-di-puglia-igp.webp"
                            type="image/webp"
                        />
                        <img
                            class="rav-image__img"
                            ngSrc="/assets/images-opt/logos/logo-olio-di-puglia-igp.png"
                            width="50"
                            height="50"
                            alt=""
                        />
                    </picture>
                    <picture class="rav-image">
                        <source
                            class="rav-image__source"
                            ngSrcset="/assets/images-opt/logos/logo-primitivo-di-manduria.png"
                            type="image/png"
                        />
                        <source
                            class="rav-image__source"
                            ngSrcset="/assets/images-opt/logos/logo-primitivo-di-manduria.webp"
                            type="image/webp"
                        />
                        <img
                            class="rav-image__img"
                            ngSrc="/assets/images-opt/logos/logo-primitivo-di-manduria.png"
                            width="215"
                            height="50"
                            alt="logo primitivo di manduria"
                        />
                    </picture>

                    <picture class="rav-image">
                        <source class="rav-image__source" ngSrcset="/assets/images-opt/logos/logo-salice-salentino.png" type="image/png" />
                        <source
                            class="rav-image__source"
                            ngSrcset="/assets/images-opt/logos/logo-salice-salentino.webp"
                            type="image/webp"
                        />
                        <img
                            class="rav-image__img"
                            ngSrc="/assets/images-opt/logos/logo-salice-salentino.png"
                            width="90"
                            height="50"
                            alt="logo salice salentino"
                        />
                    </picture>

                    <picture class="rav-image">
                        <source
                            class="rav-image__source"
                            ngSrcset="/assets/images-opt/logos/logo-brindisi-squinzano.png"
                            type="image/png"
                        />
                        <source
                            class="rav-image__source"
                            ngSrcset="/assets/images-opt/logos/logo-brindisi-squinzano.webp"
                            type="image/webp"
                        />
                        <img
                            class="rav-image__img"
                            ngSrc="/assets/images-opt/logos/logo-brindisi-squinzano.png"
                            width="50"
                            height="50"
                            alt="logo brindisi squinzano"
                        />
                    </picture>
                </div>
            </div>
        </div>
        <div class="rav-section -row -medium -sitemap">
            <div class="rav-block -col">
                <div class="rav-list">
                    <h4 class="rav-copy -titleXS -brand-04">{{ t('footer.discover_territory') }}</h4>

                    <ul class="rav-list__links">
                        <li class="rav-list__linksItem" *ngFor="let item of territory?.data">
                            <p class="rav-copy -textM" *ngIf="item.language[0]">
                                <a [routerLink]="[constants.ROUTE_TERRITORY, item?.id]" aria-label="territory link">{{
                                    item.language[0].title
                                }}</a>
                            </p>
                        </li>
                        <li class="rav-list__linksItem">
                            <p class="rav-copy -textM">
                                <a
                                    [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_INTEREST_PLACE]"
                                    aria-label="interest place link"
                                    >{{ t('footer.interest_place') }}</a
                                >
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="rav-block -col">
                <div class="rav-list">
                    <h4 class="rav-copy -titleXS -brand-01-700">{{ t('footer.top_product') }}</h4>

                    <ul class="rav-list__links">
                        <li class="rav-list__linksItem" *ngFor="let item of master?.data">
                            <p class="rav-copy -textM" *ngIf="item.language[0]">
                                <a [routerLink]="[constants.ROUTE_MASTER_PRODUCT, item?.id]" aria-label="master product link">{{
                                    item.language[0].title
                                }}</a>
                            </p>
                        </li>
                        <li class="rav-list__linksItem">
                            <p class="rav-copy -textM">
                                <a
                                    [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_RECIPES_DETAIL]"
                                    aria-label="recipes detail link"
                                    >{{ t('footer.regional_recipes') }}</a
                                >
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="rav-block -col">
                <div class="rav-list">
                    <h4 class="rav-copy -titleXS -brand-05">{{ t('footer.experiences') }}</h4>

                    <ul class="rav-list__links">
                        <li class="rav-list__linksItem">
                            <p class="rav-copy -textM">
                                <a [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_TOURS]" aria-label="tours link">{{
                                    t('footer.tours')
                                }}</a>
                            </p>
                        </li>
                        <li class="rav-list__linksItem">
                            <p class="rav-copy -textM">
                                <a
                                    [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_INTEREST_PLACE]"
                                    aria-label="interest place link"
                                    >{{ t('footer.interest_place') }}</a
                                >
                            </p>
                        </li>
                        <!-- <li class="rav-list__linksItem">
                            <p class="rav-copy -textM">
                                <a [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_TASTING]" aria-label="tasting link">{{
                                    t('footer.tasting')
                                }}</a>
                            </p>
                        </li> -->
                        <li class="rav-list__linksItem">
                            <p class="rav-copy -textM">
                                <a [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_EVENTS]" aria-label="events link">{{
                                    t('footer.events')
                                }}</a>
                            </p>
                        </li>
                        <!-- <li class="rav-list__linksItem">
                            <p class="rav-copy -textM">
                                <a [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_MEDIA]" aria-label="media link">{{
                                    t('footer.media')
                                }}</a>
                            </p>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="rav-block -col">
                <div class="rav-list">
                    <h4 class="rav-copy -titleXS -ui-01">{{ t('footer.name_project') }}</h4>

                    <ul class="rav-list__links">
                        <li class="rav-list__linksItem" *ngFor="let item of miscs?.data">
                            <p class="rav-copy -textM" *ngIf="item.language[0]">
                                <a
                                    [routerLink]="[constants.ROUTE_GENERIC_ARTICLE, constants.ARTICLE_MISC, item?.id]"
                                    aria-label="generic misc link"
                                    >{{ item.language[0].title }}</a
                                >
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="rav-section -col -medium -accordions">
            <div class="rav-accordion" ravAccordion data-group="" [ngClass]="{ '-opened': isOpenMenu }">
                <div class="rav-accordion__header" role="button">
                    <h5 class="rav-copy -titleXS">{{ t('footer.discover_territory') }}</h5>

                    <div class="rav-icon rav-icon-plus-circle -brand-04"></div>
                </div>
                <div class="rav-accordion__container">
                    <div class="rav-accordion__content">
                        <ul class="rav-accordion__list">
                            <li class="rav-list__linksItem" *ngFor="let item of territory?.data" (click)="openMenu()">
                                <p class="rav-copy -textM" *ngIf="item.language[0]">
                                    <a [routerLink]="[constants.ROUTE_TERRITORY, item?.id]" aria-label="territory link">
                                        {{ item.language[0].title }}
                                    </a>
                                </p>
                            </li>
                            <li class="rav-list__linksItem">
                                <p class="rav-copy -textM">
                                    <a
                                        [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_INTEREST_PLACE]"
                                        aria-label="interest place link"
                                    >
                                        {{ t('footer.interest_place') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="rav-accordion" ravAccordion data-group="">
                <div class="rav-accordion__header" role="button">
                    <h5 class="rav-copy -titleXS">{{ t('footer.top_product') }}</h5>

                    <div class="rav-icon rav-icon-plus-circle -brand-04"></div>
                </div>
                <div class="rav-accordion__container">
                    <div class="rav-accordion__content">
                        <ul class="rav-accordion__list">
                            <li class="rav-list__linksItem" *ngFor="let item of master?.data" (click)="openMenu()">
                                <p class="rav-copy -textM" *ngIf="item.language[0]">
                                    <a [routerLink]="[constants.ROUTE_MASTER_PRODUCT, item?.id]" aria-label="master product link">
                                        {{ item.language[0].title }}
                                    </a>
                                </p>
                            </li>
                            <li class="rav-list__linksItem">
                                <p class="rav-copy -textM">
                                    <a
                                        [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_RECIPES_DETAIL]"
                                        aria-label="recipes link"
                                        >{{ t('footer.regional_recipes') }}</a
                                    >
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="rav-accordion" ravAccordion data-group="">
                <div class="rav-accordion__header" role="button">
                    <h5 class="rav-copy -titleXS">{{ t('footer.experiences') }}</h5>

                    <div class="rav-icon rav-icon-plus-circle -brand-04"></div>
                </div>
                <div class="rav-accordion__container">
                    <div class="rav-accordion__content">
                        <ul class="rav-accordion__list">
                            <li class="rav-list__linksItem" (click)="openMenu()">
                                <p class="rav-copy -textM">
                                    <a [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_TOURS]" aria-label="tours link">
                                        {{ t('footer.tours') }}
                                    </a>
                                </p>
                            </li>
                            <li class="rav-list__linksItem" (click)="openMenu()">
                                <p class="rav-copy -textM">
                                    <a
                                        [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_INTEREST_PLACE]"
                                        aria-label="interest places link"
                                    >
                                        {{ t('footer.interest_place') }}
                                    </a>
                                </p>
                            </li>
                            <!-- <li class="rav-list__linksItem" (click)="openMenu()">
                                <p class="rav-copy -textM">
                                    <a [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_TASTING]" aria-label="tasting link">{{
                                        t('footer.tasting')
                                    }}</a>
                                </p>
                            </li> -->
                            <li class="rav-list__linksItem" (click)="openMenu()">
                                <p class="rav-copy -textM">
                                    <a [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_EVENTS]" aria-label="events link">
                                        {{ t('footer.events') }}
                                    </a>
                                </p>
                            </li>
                            <!-- <li class="rav-list__linksItem" (click)="openMenu()">
                                <p class="rav-copy -textM">
                                    <a [routerLink]="[constants.ROUTE_EXPERIENCES, constants.ARTICLE_MEDIA]" aria-label="media link">{{
                                        t('footer.media')
                                    }}</a>
                                </p>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="rav-accordion" ravAccordion data-group="footer">
                <div class="rav-accordion__header" role="button">
                    <h5 class="rav-copy -titleXS">{{ t('footer.name_project') }}</h5>

                    <div class="rav-icon rav-icon-plus-circle -brand-04"></div>
                </div>
                <div class="rav-accordion__container">
                    <div class="rav-accordion__content">
                        <ul class="rav-accordion__list">
                            <li class="rav-list__linksItem" *ngFor="let item of miscs?.data">
                                <p class="rav-copy -textM" *ngIf="item.language[0]">
                                    <a
                                        (click)="openMenu()"
                                        [routerLink]="[constants.ROUTE_GENERIC_ARTICLE, constants.ARTICLE_MISC, item?.id]"
                                        aria-label="generic article misc link"
                                    >
                                        {{ item.language[0].title }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="rav-section -col -medium -copyright">
            <p class="rav-copy -textS -uppercase -bold -brand-01-700 -center -copyright"><i>&copy;2023 Radici Virtuose</i></p>

            <p class="rav-copy -textM -center -info">
                <strong>Ufficio del programma di Promozione c/o DAJS - Distretto agroalimentare di qualità jonico salentino scart</strong>
                <br />Sede legale - Lecce, Via Oberdan 11 | Sede operativa Castromediano di Cavallino (LE), Via Zara 17 / Via Torino 13
            </p>

            <p class="rav-copy -textS -uppercase -bold -center -usefulLink">
                <a href="https://www.iubenda.com/privacy-policy/11595301" target="_blank" aria-label="info privacy">
                    <i>{{ t('footer.privacy') }}</i>
                </a>
                <a href="https://www.iubenda.com/privacy-policy/11595301/cookie-policy" target="_blank" aria-label="info privacy">
                    <i>{{ t('footer.cookie_policy') }}</i>
                </a>
                <!-- - <a href="#" aria-label="info legal notice"><i>legal notice</i></a> -
                <a href="#" aria-label="info press"><i>press</i></a> -->
            </p>
        </div>
    </div>
</div>
