import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Constants } from '../constants/constants';
import { MasterDetailProducts, MasterProduct, ProductDetail, TopProduct } from '../models/product.model';
import { LocalStorageManagementService } from './local-storage-management.service';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    constructor(
        //
        private http: HttpClient,
        private localStorageManagementService: LocalStorageManagementService
    ) {}

    // ─── HTTP REQUESTS - GET ─────────────────────────────────────────────────────

    getProducts(): Observable<TopProduct[]> {
        const languageID = this.localStorageManagementService.getItem(Constants.LANGUAGE_INDEX);
        return this.http.get<TopProduct[]>(
            `items/top_products?fields=*,language.*&deep[language][_filter][languages_code][_eq]=${languageID}`
        );
    }

    getProductDetail(productId?: number): Observable<ProductDetail> {
        const languageID = this.localStorageManagementService.getItem(Constants.LANGUAGE_INDEX);
        return this.http.get<ProductDetail>(
            `items/top_products/${productId}?deep[master_top_product_id][language][_filter][languages_code][_eq]=${languageID}&deep[language][_filter][languages_code][_eq]=${languageID}&fields=*,language.*,master_top_product_id.*,master_top_product_id.top_product_ids.*,master_top_product_id.top_product_ids.language.*,master_top_product_id.language.*&deep[master_top_product_id][top_product_ids][language][_filter][languages_code][_eq]=${languageID}`
        );
    }

    getOtherProducts(): Observable<MasterDetailProducts[]> {
        const languageID = this.localStorageManagementService.getItem(Constants.LANGUAGE_INDEX);
        return this.http.get<MasterDetailProducts[]>(
            `items/other_products?fields=*,language.*&deep[language][_filter][languages_code][_eq]=${languageID}`
        );
    }

    getMasterTopProducts(): Observable<MasterProduct> {
        const languageID = this.localStorageManagementService.getItem(Constants.LANGUAGE_INDEX);
        return this.http.get<MasterProduct>(
            `items/master_top_products?fields=*,language.*&deep[language][_filter][languages_code][_eq]=${languageID}&sort=sort`
        );
    }

    getMasterTopProductsByTerritory(idTerritory: number): Observable<MasterProduct> {
        const languageID = this.localStorageManagementService.getItem(Constants.LANGUAGE_INDEX);
        return this.http.get<MasterProduct>(
            `items/master_top_products?fields=*,language.*,master_producer_id.producer_ids.*,master_producer_id.producer_ids.producers_id.*,master_producer_id.producer_ids.producers_id.language.*&deep[language][_filter][languages_code][_eq]=${languageID}&filter[territory_id][_eq]=${idTerritory}`
        );
    }

    getMasterTopProductsByProducer(producerId: number): Observable<any> {
        const languageID = this.localStorageManagementService.getItem(Constants.LANGUAGE_INDEX);
        return this.http.get<MasterProduct>(
            `items/master_top_products?fields=*,language.*,master_producer_id.producer_ids.*,master_producer_id.producer_ids.producers_id.*,master_producer_id.producer_ids.producers_id.language.*&deep[language][_filter][languages_code][_eq]=${languageID}&filter[master_producer_id][producer_ids][producers_id][id][_eq]=${producerId}`
        );
    }

    getMasterTopProductsDetail(masterTopProductId: number = 1): Observable<MasterDetailProducts> {
        const languageID = this.localStorageManagementService.getItem(Constants.LANGUAGE_INDEX);
        return this.http.get<MasterDetailProducts>(
            `items/master_top_products/${masterTopProductId}?fields=*,language.*,language.master_top_products_id.*,language.master_top_products_id.language.*,top_product_ids.*,top_product_ids.language.*,master_producer_id.*,master_producer_id.language.*,territory_id.*,master_producer_id.top_product_ids.*,territory_id.language.*&deep[language][_filter][languages_code][_eq]=${languageID}&deep[top_product_ids][language][_filter][languages_code][_eq]=${languageID}&deep[master_producer_id][language][_filter][languages_code][_eq]=${languageID}&deep[territory_id][language][_filter][languages_code][_eq]=${languageID}`
        );
    }

    getMasterTopProductsByMasterTopProduct(masterTopProductId: number): Observable<MasterProduct> {
        const languageID = this.localStorageManagementService.getItem(Constants.LANGUAGE_INDEX);
        return this.http.get<MasterProduct>(
            `items/master_top_products?fields=*,language.*,language.master_top_products_id.*,language.master_top_products_id.language.*,top_product_ids.*,top_product_ids.language.*,master_producer_id.*,master_producer_id.language.*,territory_id.*,master_producer_id.top_product_ids.*,territory_id.language.*&deep[language][_filter][languages_code][_eq]=${languageID}&deep[top_product_ids][language][_filter][languages_code][_eq]=${languageID}&deep[master_producer_id][language][_filter][languages_code][_eq]=${languageID}&deep[territory_id][language][_filter][languages_code][_eq]=${languageID}&filter[top_product_ids][master_top_product_id][_eq]=${masterTopProductId}`
        );
    }
}
