import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';

import { filter, map, mergeMap, Subscription } from 'rxjs';

import { VERSION } from '@env/version';

import { SEOService } from './core/services/base/seo.service';
import { LanguageService } from './core/services/language.service';
import { LocalStorageManagementService } from './core/services/local-storage-management.service';

@Component({
    selector: 'rav-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private routerSeoSubscription!: Subscription;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private seoService: SEOService,
        //,
        private languageService: LanguageService,
        private localStorage: LocalStorageManagementService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit(): void {
        this.setupAppVersion();
        this.languageService.setupAppLanguage();

        this.routerSeoSubscription = this.router.events
            .pipe(
                filter((event: Event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route: ActivatedRoute) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route: ActivatedRoute) => route.outlet === 'primary'),
                mergeMap((route: ActivatedRoute) => route.data)
            )
            .subscribe((event: any) => {
                (<any>window).dataLayer.push({
                    event: 'historyChange',
                });

                this.seoService.updateTitle(event.title);
                this.seoService.updateOpenGraph(event.title, event.description, event.ogImageUrl);
                this.seoService.updateDescription(event.description);
                this.seoService.updateRobots(event.index, event.follow);
                this.seoService.updateHreflang(event.hreflang);
                this.seoService.updateCanonical(event.canonical);
                this.seoService.updateJsonLD(event.title, event.description, event.jsonLDWebPage, event.jsonLD);
            });
    }

    setupAppVersion(): void {
        const version = this.document.createElement('script');
        version.type = 'text/javascript';
        version.text = `window.appVersion = function() { return '${VERSION.version} - ${VERSION.hash}' }`;
        this.document.body.appendChild(version);
    }

    ngOnDestroy(): void {
        if (this.routerSeoSubscription) {
            this.routerSeoSubscription.unsubscribe();
        }
    }
}
