import { Injectable } from '@angular/core';

import { Constants } from '@app/core/constants/constants';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageManagementService {
    constructor() {}

    //
    // ─── GENERIC - METHODS ──────────────────────────────────────────────────────────
    //

    getLocalStorage(): string {
        return JSON.stringify(localStorage);
    }

    getItem(item: string): string | null {
        return localStorage.getItem(item) ? JSON.parse(localStorage.getItem(item)!) : null;
    }

    setItem(item: string, value: any): void {
        localStorage.setItem(item, JSON.stringify(value));
    }

    removeItem(item: string): void {
        localStorage.removeItem(item);
    }

    clearLocalStorage(): void {
        localStorage.clear();
    }

    deleteLanguage(): void {
        localStorage.removeItem(Constants.LANGUAGE);
    }
}
