import { Component, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';

import { Subscription } from 'rxjs';

import { Constants } from '@app/core/constants/constants';
import { Misc } from '@app/core/models/misc.model';
import { MasterProduct } from '@app/core/models/product.model';
import { Territory } from '@app/core/models/territory.model';
import { AccordionDirective } from '@app/shared/directives/accordion.directive';

@Component({
    selector: 'rav-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy, OnChanges {
    public constants = Constants;
    public isOpenMenu: boolean = false;
    public isOpenedSearch: boolean = false;

    public master!: MasterProduct;
    public territory!: Territory;
    public miscs!: Misc;

    private routerSubscription!: Subscription;

    @Input() masterProducts!: MasterProduct;
    @Input() territories!: Territory;
    @Input() misc!: Misc;

    @ViewChildren(AccordionDirective) accordions!: QueryList<AccordionDirective>;

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.routerSubscription = this.router.events.subscribe((instance: Event) => {
            if (instance instanceof NavigationEnd) {
                this.closeAllAccordions();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['masterProducts']) {
            this.master = this.masterProducts;
        }
        if (changes['territories']) {
            this.territory = this.territories;
        }
        if (changes['misc']) {
            this.miscs = this.misc;
        }
    }

    expandSearch(): void {
        this.isOpenedSearch = !this.isOpenedSearch;
    }

    openMenu(): void {
        this.isOpenMenu = false;
        if (window.innerWidth > 1024) {
            this.expandSearch();
        }
    }

    closeAllAccordions(): void {
        this.accordions.forEach((accordion: AccordionDirective) => {
            accordion.close();
        });
    }

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }
}
