import { DOCUMENT } from '@angular/common';
import { Injectable, Inject, ViewChild, ElementRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { JsonLdService } from '@app/core/services/base/json-ld.service';

import { JsonLd } from '@app/core/models/base/json-ld.model';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class SEOService {
    @ViewChild('canonical', { static: false }) canonical!: ElementRef;

    constructor(
        private jsonLdService: JsonLdService,
        private meta: Meta,
        private router: Router,
        private title: Title,
        @Inject(DOCUMENT) private document: any
    ) {}

    updateTitle(title: string): void {
        this.title.setTitle(title !== 'Radici Virtuose' && title !== undefined ? title + ' | Radici Virtuose' : 'Radici Virtuose');
    }

    updateDescription(description: string): void {
        const descriptionParsed = this.parseDescription(description);
        this.meta.updateTag({
            name: 'description',
            content: descriptionParsed.length >= 160 ? descriptionParsed.substring(0, 157) + '...' : descriptionParsed,
        });
    }

    updateOpenGraph(title: string, description: string, ogImageUrl?: string): void {
        const descriptionParsed = this.parseDescription(description);

        this.meta.updateTag({ property: 'og:title', content: title });
        this.meta.updateTag({
            property: 'og:description',
            content: descriptionParsed.length >= 160 ? descriptionParsed.substring(0, 157) + '...' : descriptionParsed,
        });
        this.meta.updateTag({ property: 'og:url', content: `${environment.feUrl}${this.document.location.pathname.substring(1)}` });
        this.meta.updateTag({
            property: 'og:image',
            content: `${environment.feUrl}assets/${ogImageUrl ? ogImageUrl : 'images-opt/social/share-radici-virtuose.png'}`,
        });
    }

    updateRobots(index: boolean = true, follow: boolean): void {
        if (follow === undefined) {
            follow = index;
        }
        this.meta.updateTag({
            name: 'robots',
            content: `${index === true ? 'index' : 'noindex'}, ${follow === true ? 'follow' : 'nofollow'}`,
        });
    }

    // TODO
    updateHreflang(href: string): void {
        // const hrefLangList = this.document.querySelectorAll('link[rel="alternate"]');
        // if (href) {
        //     hrefLangList[0].href = environment.feUrl + this.localStorage.getItem(Constants.LANGUAGE_INDEX)?.toLocaleLowerCase() + href;
        //     if (hrefLangList[1]) {
        //         hrefLangList[1].href = environment.feUrl;
        //     }
        // }
    }

    updateCanonical(href: string): void {
        if (href) {
            this.document.querySelector('link[rel="canonical"]').href = environment.feUrl + href;
        }
    }

    updateJsonLD(title: string, description: string, jsonLDWebPage: JsonLd, jsonLD: JsonLd | JsonLd[]): void {
        if (!jsonLD) {
            jsonLD = [];
        }

        let jsonLDList = [];
        if (Array.isArray(jsonLD)) {
            jsonLDList = [this.generateLogoJsonLD(), this.generateWebPageJsonLD(title, description, jsonLDWebPage), ...jsonLD];
        } else {
            jsonLDList = [this.generateLogoJsonLD(), this.generateWebPageJsonLD(title, description, jsonLDWebPage), jsonLD];
        }
        this.jsonLdService.setData(jsonLDList);
    }

    generateLogoJsonLD(): any {
        return {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: environment.feUrl,
            logo: `${environment.feUrl}assets/images/share-radici-virtuose.png`,
        };
    }

    generateWebPageJsonLD(title: string, description: string, jsonLDWebPage: JsonLd): JsonLd {
        const descriptionParsed = this.parseDescription(description);

        return {
            '@context': 'http://schema.org',
            '@type': 'WebPage',
            '@id': this.router.url,
            url: this.router.url,
            inLanguage: {
                '@type': 'Language',
                name: 'Italian',
                alternateName: 'it',
            },
            name: title,
            description: descriptionParsed.length >= 160 ? descriptionParsed.substring(0, 157) + '...' : descriptionParsed,
            ...jsonLDWebPage,
        };
    }

    parseDescription(description: string): string {
        return description ? description.replace(/<\/?[^>]+(>|$)/g, '') : '';
    }
}
