import { Injectable } from '@angular/core';

import { Constants } from '../constants/constants';
import { Entity } from '../models/entity.model';
import { ComponentTitleSlider, DataSliderTitle, SliderTitle } from '../models/slider-title.model';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    public constants = Constants;

    constructor() {}

    // ─── ROUTING UTILS - METHODS ────────────────────────────────────────────────────

    findDataPropertyOnUpperChild(property: string, instance: any): unknown | undefined {
        if (instance.firstChild === null) {
            return instance.data[property];
        } else {
            const value = this.findDataPropertyOnUpperChild(property, instance.firstChild);
            if (value !== undefined) {
                return value;
            } else {
                return instance.data[property];
            }
        }
    }

    //
    // ─── STRING UTILS - METHODS ─────────────────────────────────────────────────────
    //

    notEmptyString(value: string): boolean {
        return value !== undefined && value !== null && value !== '';
    }

    formatToKebabCase(value: string): string {
        if (this.notEmptyString(value)) {
            return value
                .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|([A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9])+/g)!
                .map((x: string) => x.toLowerCase())
                .join('-');
        } else {
            return value;
        }
    }

    // ─── TEMPLATE UTILS - METHODS ───────────────────────────────────────────────────

    getRightTitle(titles: SliderTitle, section: string): ComponentTitleSlider {
        let data: ComponentTitleSlider = {
            title: '',
            subtitle: '',
            description: '',
        };
        titles.data.forEach((title: DataSliderTitle): void => {
            if (section === title.section) {
                data = {
                    title: title?.language[0]?.title,
                    subtitle: title?.language[0]?.subtitle,
                    description: title?.language[0]?.description,
                };
            }
        });
        return data;
    }

    getEntity(data: any, lang?: string): Entity {
        let cardData!: any;
        let titleImage = '';
        let paragraph: string | undefined = '';
        let paragraph2: string | undefined = '';
        let paragraphShort = '';
        let title = '';
        if (data) {
            titleImage = data?.title_image;
            data.language.forEach((element: any) => {
                if (element.languages_code === lang) {
                    paragraph = element.paragraph;
                    paragraph2 = element?.paragraph_2;
                    paragraphShort = element.short_description;
                    title = element.title;
                    cardData = element;
                }
            });
        }

        return {
            paragraph,
            paragraph2,
            paragraphShort,
            title,
            titleImage,
            cardData,
            data,
        };
    }
}
