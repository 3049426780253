import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutPagesComponent } from './shared/layouts/layout-pages/layout-pages.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutPagesComponent,
        children: [
            {
                path: '',
                data: {
                    isHeaderColored: false,
                    title: 'Radici Virtuose',
                    canonical: '/',
                    hreflang: '',
                },
                loadChildren: () =>
                    import('./features/home/home.module').then((m: typeof import('./features/home/home.module')) => m.HomeModule),
            },
            {
                path: 'master-top-products',
                data: {
                    canonical: '/master-top-products',
                    hreflang: '/master-top-products',
                },
                loadChildren: () =>
                    import('./features/category/category.module').then(
                        (m: typeof import('./features/category/category.module')) => m.CategoryModule
                    ),
            },
            {
                path: 'product-detail',
                data: {
                    isHeaderColored: true,
                    canonical: '/product-detail',
                    hreflang: '/product-detail',
                },
                loadChildren: () =>
                    import('./features/product-detail/product-detail.module').then(
                        (m: typeof import('./features/product-detail/product-detail.module')) => m.ProductDetailModule
                    ),
            },
            {
                path: 'article',
                data: {
                    isHeaderColored: true,
                },
                loadChildren: () =>
                    import('./features/generic-article/generic-article.module').then(
                        (m: typeof import('./features/generic-article/generic-article.module')) => m.GenericArticleModule
                    ),
            },
            {
                path: 'producers',
                data: {
                    canonical: '/producers',
                    hreflang: '/producers',
                },
                loadChildren: () =>
                    import('./features/producers/producers.module').then(
                        (m: typeof import('./features/producers/producers.module')) => m.ProducersModule
                    ),
            },
            {
                path: 'territories',
                data: {
                    canonical: '/territories',
                    hreflang: '/territories',
                },
                loadChildren: () =>
                    import('./features/master-territory/master-territory.module').then(
                        (m: typeof import('./features/master-territory/master-territory.module')) => m.MasterTerritoryModule
                    ),
            },
            {
                path: 'maps',
                data: {
                    isHeaderColored: true,
                    canonical: '/maps',
                    hreflang: '/maps',
                },
                loadChildren: () =>
                    import('./features/territory/territory.module').then(
                        (m: typeof import('./features/territory/territory.module')) => m.TerritoryModule
                    ),
            },
            {
                path: 'producer-detail',
                data: {
                    isHeaderColored: true,
                    canonical: '/producer-detail',
                    hreflang: '/producer-detail',
                },
                loadChildren: () =>
                    import('./features/producer-detail/producer-detail.module').then(
                        (m: typeof import('./features/producer-detail/producer-detail.module')) => m.ProducerDetailModule
                    ),
            },
            {
                path: 'interest-place-detail',
                data: {
                    isHeaderColored: true,
                    canonical: '/interest-place-detail',
                    hreflang: '/interest-place-detail',
                },
                loadChildren: () =>
                    import('./features/interest-place-detail/interest-place-detail.module').then(
                        (m: typeof import('./features/interest-place-detail/interest-place-detail.module')) => m.InterestPlaceDetailModule
                    ),
            },
            {
                path: 'experiences',
                loadChildren: () =>
                    import('./features/experience/experience.module').then(
                        (m: typeof import('./features/experience/experience.module')) => m.ExperienceModule
                    ),
            },
            {
                path: 'recipe-detail',
                data: {
                    isHeaderColored: true,
                    canonical: '/recipe-detail',
                    hreflang: '/recipe-detail',
                },
                loadChildren: () =>
                    import('./features/recipe-detail/recipe-detail.module').then(
                        (m: typeof import('./features/recipe-detail/recipe-detail.module')) => m.RecipeDetailModule
                    ),
            },
            {
                path: 'media',
                data: {
                    isHeaderColored: true,
                    canonical: '/media',
                    hreflang: '/media',
                },
                loadChildren: () =>
                    import('./features/media/media.module').then((m: typeof import('./features/media/media.module')) => m.MediaModule),
            },
        ],
    },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
