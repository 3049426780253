export class Constants {
    // LANGUAGES

    public static readonly IT = 'it-IT';

    // ANGOLIA

    public static readonly ALGOLIA_APP_ID = 'X-Algolia-Application-Id';
    public static readonly ALGOLIA_API_KEY = 'X-Algolia-API-Key';
    public static readonly ANGOLIA_INDEXES = [
        'tours_translations',
        'recipes_translations',
        'tasting_translations',
        'events_translations',
        'interest_places_translations',
        'misc_translations',
        'media_translations',

        'master_top_products_translations',
        'top_products_translations',
        'maps_translations',
        'producers_translations',
        'master_producers_translations',
        'territories_translations',
        'other_products_translations',
    ];

    public static readonly CAROUSEL_EXPERIENCE = 'EXPERIENCE';
    public static readonly CAROUSEL_EVENTS = 'EVENTS';
    public static readonly CAROUSEL_MASTER_PRODUCT = 'MASTER_PRODUCT';
    public static readonly CAROUSEL_PRODUCT = 'PRODUCT';
    public static readonly CAROUSEL_HERO_SECTION = 'HERO_SECTION';
    public static readonly CAROUSEL_INTEREST_PLACE = 'INTEREST_PLACE';
    public static readonly CAROUSEL_MEDIA = 'MEDIA';
    public static readonly CAROUSEL_RECIPES = 'RECIPES';
    public static readonly CAROUSEL_PRODUCER = 'PRODUCER';

    // ROUTES

    public static readonly ROUTE_GENERIC_ARTICLE = '/article';
    public static readonly ROUTE_MAP = '/maps';
    public static readonly ROUTE_PRODUCER_DETAIL = '/producer-detail';
    public static readonly ROUTE_PRODUCT_DETAIL = '/product-detail';
    public static readonly ROUTE_TERRITORY = '/territories';
    public static readonly ROUTE_MASTER_PRODUCT = '/master-top-products';
    public static readonly ROUTE_INTEREST_PLACES = '/interest-places';
    public static readonly ROUTE_INTEREST_PLACE_DETAIL = '/interest-place-detail';
    public static readonly ROUTE_RECIPES = '/recipes';
    public static readonly ROUTE_RECIPES_DETAIL = '/recipe-detail';
    public static readonly ROUTE_PRODUCERS = '/producers';
    public static readonly ROUTE_EXPERIENCES = '/experiences';
    public static readonly ROUTE_MEDIA = '/media';
    public static readonly ROUTE_TASTING = '/tasting';

    // TYPES OF ARTICLES

    public static readonly ARTICLE_INTEREST_PLACE = 'interest-places';
    public static readonly ARTICLE_PRODUCT_DETAIL = 'product-detail';
    public static readonly ARTICLE_TOURS = 'tours';
    public static readonly ARTICLE_TASTING = 'tasting';
    public static readonly ARTICLE_RECIPES = 'recipes';
    public static readonly ARTICLE_EVENTS = 'events';
    public static readonly ARTICLE_MISC = 'misc';
    public static readonly ARTICLE_MEDIA = 'media';
    public static readonly ARTICLE_MEDIA_AUDIO = 'audio';
    public static readonly ARTICLE_MEDIA_VIDEO = 'video';
    public static readonly ARTICLE_MEDIA_GALLERY = 'gallery';
    /* NON SONO ARTICOLI - fare un altra lista types */
    public static readonly ARTICLE_MASTER_TOP_PRODUCT = 'master-top-product';
    public static readonly ARTICLE_MAP = 'maps';
    public static readonly ARTICLE_PRODUCERS = 'producer-detail';
    public static readonly ARTICLE_PRODUCER_DETAIL = 'producer-detail';
    public static readonly ARTICLE_RECIPES_DETAIL = 'recipe-detail';
    public static readonly ARTICLE_TERRITORY = 'territories';

    /* ALGOLIA */
    public static readonly ALGOLIA_MASTER_PRODUCERS = 'master-producers';
    public static readonly ALGOLIA_TOURS = 'tours';
    public static readonly ALGOLIA_RECIPES = 'recipes';
    public static readonly ALGOLIA_TASTING = 'tasting';
    public static readonly ALGOLIA_EVENTS = 'events';
    public static readonly ALGOLIA_INTEREST_PLACES = 'interest-places';
    public static readonly ALGOLIA_MISC = 'misc';
    public static readonly ALGOLIA_MEDIA = 'media';
    public static readonly ALGOLIA_MASTER_TOP_PRODUCTS = 'master-top-products';
    public static readonly ALGOLIA_TOP_PRODUCTS = 'top-products';
    public static readonly ALGOLIA_MAPS = 'maps';
    public static readonly ALGOLIA_PRODUCERS = 'producers';
    public static readonly ALGOLIA_TERRITORIES = 'territories';
    public static readonly ALGOLIA_OTHER_PRODUCTS = 'other-products';

    // TITLES SLIDER

    public static readonly TITLE_TOURS = 'TOURS';
    public static readonly TITLE_RECIPES = 'RECIPES';
    public static readonly TITLE_MASTER_TOP_PRODUCTS = 'MASTER_TOP_PRODUCTS';
    public static readonly TITLE_EVENTS = 'EVENTS';
    public static readonly TITLE_INTEREST_PLACES = 'INTEREST_PLACES';
    public static readonly TITLE_TOP_PRODUCT = 'TOP_PRODUCTS';
    public static readonly TITLE_PRODUCERS = 'PRODUCERS';
    public static readonly TITLE_MEDIA = 'MEDIA';
    public static readonly TITLE_TASTING = 'TASTINGS';

    // TYPE OF REFACTOR PARAGRAPH

    public static readonly PARAGRAPH_TYPE = 'paragraph';
    public static readonly ARTICLE_TYPE = 'article';
    public static readonly PARAGRAPH_SLIDE_TYPE = 'pragraph_slide';

    // LIMITS

    public static readonly LIMIT_LOAD_MORE = 4;

    // STATUS

    public static readonly STATUS_PUBLISHED = 'published';
    public static readonly STATUS_DRAFT = 'draft';

    // MEDIA TYPE

    public static readonly MEDIA_TYPE_AUDIO = 1;
    public static readonly MEDIA_TYPE_VIDEO = 2;
    public static readonly MEDIA_TYPE_PHOTO = 3;

    // TAG ICONS

    public static readonly TAG_ICON_PRIMITIVO_DI_MANDURIA = 'PRIMITIVO_DI_MANDURIA';
    public static readonly TAG_ICON_SALICE_SALENTINO = 'SALICE_SALENTINO';
    public static readonly TAG_ICON_BRINDISI = 'BRINDISI';
    public static readonly TAG_ICON_OLIO_DI_PUGLIA = 'OLIO_DI_PUGLIA';

    //
    // ─── LANGUAGE ───────────────────────────────────────────────────────────────────
    //

    public static readonly LANGUAGE = 'LANGUAGE';
    public static readonly LANGUAGE_INDEX = 'LANGUAGE_INDEX';
    public static readonly LANGUAGE_ITALIAN = 'it';
    public static readonly LANGUAGE_ENGLISH = 'en';
    public static readonly LANGUAGE_GERMAN = 'de';
    public static readonly LANGUAGE_FRENCH = 'fr';
    public static readonly LANGUAGE_ITALIAN_INDEX = 'it-IT';
    public static readonly LANGUAGE_ENGLISH_INDEX = 'en-US';
    public static readonly LANGUAGE_GERMAN_INDEX = 'de-DE';
    public static readonly LANGUAGE_FRENCH_INDEX = '';

    public static readonly LANGUAGES = {
        it: 'Ita',
        en: 'Eng',
    };
    public static readonly LANGUAGES_BE = {
        ['it']: '0',
        ['en']: '1',
    };
}
