import { TranslocoService } from '@ngneat/transloco';

import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';

import { debounceTime, distinctUntilChanged, filter, fromEvent, map, Subscription } from 'rxjs';

import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation } from 'swiper';

import { SwiperComponent } from 'swiper/angular';

import algoliasearch, { SearchClient } from 'algoliasearch';

import { LanguageService } from '@app/core/services/language.service';
import { LocalStorageManagementService } from '@app/core/services/local-storage-management.service';
import { UtilsService } from '@app/core/services/utils.service';

import { Constants } from '@app/core/constants/constants';
import { Misc } from '@app/core/models/misc.model';
import { MasterProduct } from '@app/core/models/product.model';
import { Territory } from '@app/core/models/territory.model';
import { AccordionDirective } from '@app/shared/directives/accordion.directive';

import { environment } from '@env/environment';

SwiperCore.use([Navigation, Autoplay, Pagination]);

@Component({
    selector: 'rav-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    public isMenuOpen: boolean = false;
    public isSearchOpen: boolean = false;
    public isSearchResultOpen: boolean = false;
    public showNoResultText: boolean = false;

    public isColored: boolean = false;

    public swiperHeaderConfig!: SwiperOptions;
    public swiperSubMenuConfig!: SwiperOptions;
    public currentLanguage!: string;
    public currentLanguageIndex!: any;

    public master!: MasterProduct;
    public territory!: Territory;
    public miscs!: Misc;

    public constants = Constants;
    public search: string = '';
    public filteredResponse: any[] = [];
    public findRightLanguage!: any;

    private routerSubscription!: Subscription;
    private searchChangeSubscription!: Subscription;
    private algoliaClient!: SearchClient;

    @Input() masterProducts!: MasterProduct;
    @Input() territories!: Territory;
    @Input() misc!: Misc;

    @ViewChild('swiperHeader', { static: false }) swiperHeader?: SwiperComponent;
    @ViewChild('swiperSubMenu', { static: false }) swiperSubMenu?: SwiperComponent;
    @ViewChild('searchBar') searchBar?: ElementRef;

    @ViewChildren(AccordionDirective) accordions!: QueryList<AccordionDirective>;

    constructor(
        //,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private utilsService: UtilsService,
        private translocoService: TranslocoService,
        private languageService: LanguageService,
        private localStorage: LocalStorageManagementService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['masterProducts']) {
            this.master = this.masterProducts;
        }
        if (changes['territories']) {
            this.territory = this.territories;
        }
        if (changes['misc']) {
            this.miscs = this.misc;
        }
    }

    ngOnInit(): void {
        this.currentLanguage = this.translocoService.getActiveLang();
        this.currentLanguageIndex = this.localStorage.getItem(this.constants.LANGUAGE_INDEX);
        this.initializeSwiperHeaderConfig();
        this.initializeRouteSubscription();
        this.retrieveHeaderColor();

        this.algoliaClient = algoliasearch(environment.algoliaApplicationId, environment.algoliaApiKey);
    }

    ngAfterViewInit(): void {
        this.initializeSearchChangeSubscription();
    }

    // ─── HTTP REQUEST ────────────────────────────────────────────────────────────

    searchAlgoliaQuery(text: string): void {
        const indexes = [];

        const queryFilter = `languages_code:${this.currentLanguageIndex} AND status:${Constants.STATUS_PUBLISHED}`;
        for (const index of Constants.ANGOLIA_INDEXES) {
            indexes.push({
                indexName: index,
                query: text,
                filters: queryFilter,
                params: {
                    hitsPerPage: 3,
                },
            });
        }

        this.filteredResponse = [];
        this.algoliaClient.multipleQueries(indexes).then((response: any) => {
            response.results
                .filter((res: any) => {
                    return res.nbHits > 0;
                })
                .forEach((el: any) => {
                    this.filteredResponse.push(...el.hits);
                });
            this.filteredResponse.length <= 0 ? (this.showNoResultText = true) : (this.showNoResultText = false);
            this.isSearchResultOpen = true;
            this.document.body.classList.add('-overflowHidden');
        });
    }

    calculatePathByCategory(string: string, id: string, mediaType?: number): string {
        const removeTranslations = string.replace('_translations', '');
        const changeUnderScore = removeTranslations.replace('_', '-');

        let pathLink: any;

        switch (changeUnderScore) {
            case this.constants.ALGOLIA_TOURS:
                pathLink = [this.constants.ROUTE_GENERIC_ARTICLE, this.constants.ARTICLE_TOURS, id];
                break;
            case this.constants.ALGOLIA_RECIPES:
                pathLink = [this.constants.ARTICLE_RECIPES_DETAIL, id];
                break;
            case this.constants.ALGOLIA_MEDIA:
                pathLink = [this.constants.ARTICLE_MEDIA, mediaType, +id];
                break;
            case this.constants.ALGOLIA_INTEREST_PLACES:
                pathLink = [this.constants.ROUTE_GENERIC_ARTICLE, this.constants.ARTICLE_INTEREST_PLACE, id];
                break;
            case this.constants.ALGOLIA_TASTING:
                pathLink = [this.constants.ROUTE_GENERIC_ARTICLE, this.constants.ARTICLE_TASTING, id];
                break;
            case this.constants.ALGOLIA_MISC:
                pathLink = [this.constants.ROUTE_GENERIC_ARTICLE, this.constants.ARTICLE_MISC, id];
                break;
            case this.constants.ALGOLIA_MASTER_PRODUCERS:
                pathLink = [this.constants.ROUTE_MASTER_PRODUCT, id];
                break;
            case this.constants.ALGOLIA_TOP_PRODUCTS:
                pathLink = [this.constants.ROUTE_PRODUCT_DETAIL, id];
                break;
            case this.constants.ALGOLIA_EVENTS:
                pathLink = [this.constants.ROUTE_GENERIC_ARTICLE, this.constants.ARTICLE_EVENTS, id];
                break;
            case this.constants.ALGOLIA_PRODUCERS:
                pathLink = [this.constants.ROUTE_PRODUCER_DETAIL, id];
                break;
            case this.constants.ALGOLIA_TERRITORIES:
                pathLink = [this.constants.ROUTE_TERRITORY, id];
                break;
            case this.constants.ALGOLIA_MAPS:
                pathLink = [this.constants.ROUTE_MAP, id];
                break;

            default:
                break;
        }
        return pathLink;
    }

    // ─── ACCORDION - METHODS ─────────────────────────────────────────────────────

    closeAllAccordions(): void {
        this.accordions.forEach((accordion: AccordionDirective) => {
            accordion.close();
        });
    }

    closeOthersAccordions(accordionNameToStayOpen: string): void {
        this.accordions.forEach((accordion: AccordionDirective) => {
            if (accordion.accordionName !== accordionNameToStayOpen) {
                accordion.close();
            }
        });
    }

    // ─── SWIPER METHODS ──────────────────────────────────────────────────────────

    initializeSwiperHeaderConfig(): void {
        this.swiperHeaderConfig = {
            slidesPerView: 1,
            effect: 'fade',
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
        };

        this.swiperSubMenuConfig = {
            slidesPerView: 1,
            autoHeight: false,
            allowTouchMove: false,
        };
    }

    goToSubMenu(slideIndex: number): void {
        this.swiperSubMenu?.swiperRef.slideTo(slideIndex);
    }

    // ─── SEARCH - METHODS ────────────────────────────────────────────────────────

    closeMenuAndSearch(): void {
        this.isSearchOpen = false;
        this.isSearchResultOpen = false;
        this.isMenuOpen = false;
        this.document.body.classList.remove('-overflowHidden');
        if (this.searchBar) {
            this.searchBar.nativeElement.value = '';
        }
    }

    initializeSearchChangeSubscription(): void {
        this.searchChangeSubscription = fromEvent(this.searchBar?.nativeElement, 'keyup')
            .pipe(
                map((event: any) => {
                    return event.target.value;
                }),
                filter((res: string) => {
                    let minLength = res.length >= 2;
                    minLength ? (this.isSearchResultOpen = true) : (this.isSearchResultOpen = false);
                    return minLength;
                }),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe((text: string) => {
                this.searchAlgoliaQuery(text);
            });
    }

    //
    // ─── TEMPLATE METHODS ─────────────────────────────────
    //

    initializeRouteSubscription(): void {
        this.routerSubscription = this.router.events.subscribe((instance: Event) => {
            if (instance instanceof NavigationEnd) {
                this.retrieveHeaderColor();
                this.closeAllAccordions();
            }
        });
    }

    retrieveHeaderColor(): void {
        this.isColored = this.utilsService.findDataPropertyOnUpperChild('isHeaderColored', this.activatedRoute.snapshot) as boolean;
    }

    toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
        this.goToSubMenu(0);

        if (this.isMenuOpen) {
            this.document.body.classList.add('-overflowHidden');
        } else {
            this.document.body.classList.remove('-overflowHidden');
        }

        if (window.innerWidth > 1024) {
            this.isSearchOpen = !this.isSearchOpen;
        }
    }

    goToHome(): void {
        this.router.navigate(['']);
    }

    switchLanguage(lang: string, index: string): void {
        this.currentLanguage = lang;
        this.languageService.switchLanguage(lang, index);
    }

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }

        if (this.searchChangeSubscription) {
            this.searchChangeSubscription.unsubscribe();
        }
    }
}
