import { AfterContentInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
    selector: '[ravAccordion]',
})
export class AccordionDirective implements AfterContentInit {
    private isOpen = false;

    @Input() accordionName!: string;
    @Output() accordionOpened = new EventEmitter<string>();

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngAfterContentInit(): void {
        this.isOpen = this.elementRef.nativeElement.classList.contains('-opened');
    }

    @HostListener('click', ['$event'])
    onClick(event: any): void {
        const allowedElements = ['rav-accordion__header', 'toggleAccordion', 'rav-copy', 'rav-icon-plus', 'rav-icon'];
        const classList = Array.from(event.target.classList) as string[];

        // Toggle accordion only if user clicked on header
        if (classList.some((cssClass: string) => allowedElements.includes(cssClass))) {
            this.isOpen = !this.isOpen;

            if (this.isOpen) {
                this.open();
                this.accordionOpened.emit(this.accordionName);
            } else {
                this.close();
            }
        }
    }

    open(): void {
        const accordionContentRef = this.elementRef.nativeElement.querySelector('.rav-accordion__container');

        this.renderer.addClass(this.elementRef.nativeElement, '-opened');
        accordionContentRef.style.maxHeight = `none`;
        accordionContentRef.style.overflow = `initial`;
    }

    close(): void {
        const accordionContentRef = this.elementRef.nativeElement.querySelector('.rav-accordion__container');

        this.renderer.removeClass(this.elementRef.nativeElement, '-opened');
        accordionContentRef.removeAttribute('style');
        this.isOpen = false;
    }
}
