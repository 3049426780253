import { ImageLoaderConfig, IMAGE_LOADER, NgOptimizedImage } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslocoLoader, Translation, TRANSLOCO_CONFIG, translocoConfig, TRANSLOCO_LOADER } from '@ngneat/transloco';

import { environment } from '@env/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Constants } from './core/constants/constants';
import { CoreModule } from './core/core.module';
import { AppSettingService } from './core/services/base/app-setting.service';
import { LocalStorageManagementService } from './core/services/local-storage-management.service';
import { SharedModule } from './shared/shared.module';

const appInitializer = (appConfig: AppSettingService) => (): Promise<any> => appConfig.loadAppSetting();

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string): any {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    }
}

@Injectable({ providedIn: 'root' })
export class LanguageSettingsService {
    constructor(private localStorageManagementService: LocalStorageManagementService) {}

    getLocale(): string {
        const language = this.localStorageManagementService.getItem(Constants.LANGUAGE);
        if (!language) {
            return Constants.LANGUAGE_ITALIAN;
        }
        return language;
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        CoreModule,
        NgOptimizedImage,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        AppRoutingModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            deps: [AppSettingService],
            multi: true,
        },
        {
            provide: LOCALE_ID,
            deps: [LanguageSettingsService],
            useFactory: (languageSettingsService: LanguageSettingsService): string => languageSettingsService.getLocale(),
        },
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: [Constants.LANGUAGE_ITALIAN, Constants.LANGUAGE_ENGLISH],
                defaultLang: Constants.LANGUAGE_ITALIAN,
                fallbackLang: Constants.LANGUAGE_ITALIAN,
                reRenderOnLangChange: true,
                prodMode: environment.production,
            }),
        },
        {
            provide: TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader,
        },
        {
            provide: IMAGE_LOADER,
            useValue: (config: ImageLoaderConfig): string => {
                if (!config.src.includes('images-opt')) {
                    return `${environment.apiUrl}assets/${config.src}`;
                } else {
                    return config.src;
                }
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
