//{{host}}/{{prefix_api}}/misc?fields=id,language.title&deep[language][_filter][languages_code][_eq]=it-IT
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Constants } from '../constants/constants';
import { Misc, MiscDetail } from '../models/misc.model';
import { LocalStorageManagementService } from './local-storage-management.service';

@Injectable({
    providedIn: 'root',
})
export class MiscService {
    constructor(
        //
        private http: HttpClient,
        private localStorageManagementService: LocalStorageManagementService
    ) {}

    // ─── HTTP REQUESTS - GET ─────────────────────────────────────────────────────

    getMisc(): Observable<Misc> {
        const languageID = this.localStorageManagementService.getItem(Constants.LANGUAGE_INDEX);
        return this.http.get<Misc>(
            `items/misc?fields=*,language.*,collection.*&deep[language][_filter][languages_code][_eq]=${languageID}`
        );
    }

    getMiscDetail(idMisc?: number): Observable<MiscDetail> {
        const languageID = this.localStorageManagementService.getItem(Constants.LANGUAGE_INDEX);
        return this.http.get<MiscDetail>(
            `items/misc/${idMisc}?fields=*,language.*,collection.*&deep[language][_filter][languages_code][_eq]=${languageID}`
        );
    }
}
